import { REQUEST_DETAIL_EVENT, REQUEST_DETAIL_EVENT_SUCCESS, REQUEST_DETAIL_EVENT_FAILURE } from "../../constant/EventConstant";

const initialState = {
  eventDetail: {
    data: [],
    status: 'idle',
    isLoading: true,
  },
};

export const eventDetailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_DETAIL_EVENT:
      return {
        ...state,
        eventDetail: {
          data: [],
          status: 'fetching',
          isLoading: true,
        },
      };
    case REQUEST_DETAIL_EVENT_SUCCESS:
      return {
        ...state,
        eventDetail: {
          data: payload,
          status: 'ready',
          isLoading: false,
        },
      };
    case REQUEST_DETAIL_EVENT_FAILURE:
      return {
        ...state,
        eventDetail: {
          data: [],
          status: 'fetching',
          isLoading: false,
        },
      };
    default:
      return state;
  }
}