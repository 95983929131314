import { REQUEST_GET_EVENT_LIST, REQUEST_GET_EVENT_LIST_SUCCESS, REQUEST_GET_EVENT_LIST_FAILURE } from "../../constant/EventConstant"

const initialState = {
  eventList: {
    data: [],
    status: 'idle',
    isLoading: true,
  },
};

export const eventListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_EVENT_LIST:
      return {
        ...state,
        eventList: {
          data: [],
          status: 'fetching',
          isLoading: true,
        },
      };
    case REQUEST_GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        eventList: {
          data: payload,
          status: 'ready',
          isLoading: false,
        },
      };
    case REQUEST_GET_EVENT_LIST_FAILURE:
      return {
        ...state,
        eventList: {
          data: [],
          status: 'fetching',
          isLoading: false,
        },
      };
    default:
      return state;
  }
}