import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/member/registration/leadSlice'
import { loginReducer } from "./service/reducer/loginReducer";
import { memberRegistrationReducer } from "./service/reducer/member/memberRegistrationReducer";
import { orderJerseyReducer } from "./service/reducer/member/orderJerseyReducer";
import { memberDetailReducer } from "./service/reducer/member/memberDetailReducer";
import masterCategoryRaceReducer from "./service/reducer/masterCategoryRaceReducer";
import { membersReducer } from "./service/reducer/member/membersReducer";
import { eventListReducer } from './service/reducer/event/eventListReducer'
import { eventDetailReducer } from './service/reducer/event/eventDetailReducer'

const combinedReducer = {
    header: headerSlice,
    rightDrawer: rightDrawerSlice,
    modal: modalSlice,
    lead: leadsSlice,
    loginState: loginReducer,
    memberRegistrationSate: memberRegistrationReducer,
    orderJerseyState: orderJerseyReducer,
    memberDetailState: memberDetailReducer,
    masterCategoryRaceState: masterCategoryRaceReducer,
    membersState: membersReducer,
    eventListState: eventListReducer,
    eventDetailState: eventDetailReducer
}

export default configureStore({
    reducer: combinedReducer
})
